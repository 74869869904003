// import 'es6-promise/auto'
import Vue from 'vue'

// ----------------------------------------------------------------------

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import VueAuth from '@websanova/vue-auth'
import VueRouter from 'vue-router'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
Vue.use(VueMoment, {
    moment,
})

// import auth from './plugins/auth'
import router from '@/config/router'
import store from '@/config/store'
// Set Vue VueI18n
import i18n from '@/config/i18n';
import mixin from '@/config/mixin';
import VTooltip from 'v-tooltip';


import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

Vue.use(VTooltip)
Vue.mixin(mixin)

// Set Vue router
Vue.router = router
Vue.use(VueRouter)

// --------------------------------------------------------------------------

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

// --------------------------------------------------------------------------

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// --------------------------------------------------------------------------

import Notifications from 'vue-notification'
Vue.use(Notifications)

// --------------------------------------------------------------------------

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)

import App from './views/App.vue'
Vue.config.productionTip = false

import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);




new Vue({
	render: h => h(App),
	router,
	i18n,
	store,
	mixin
}).$mount('#app')
